import netul from '../../common/netul.html';

$(document).ready(function() {
  var tipsStatus = true;
  var clientType = true; // 是否pc端
  if (!navigator.userAgent.match(/mobile/i)) {
    clientType = true;
  } else {
    clientType = false;
  }
  console.log(clientType, '@clientType');
  function getQueryString(name) {
    var reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return decodeURI(r[2]);
    return '';
  }
  var from = getQueryString('from');
  // eslint-disable-next-line no-unused-vars
  function onTips() {
    if (tipsStatus) {
      tipsStatus = false;
      $('.tips').show();
      setTimeout(function() {
        $('.tips').hide();
        tipsStatus = true;
      }, 2000);
    }
  }
  if (window.location.href.indexOf('yuanhai777.com') >= 0) {
    $('.foot1').hide();
  } else {
    $('.foot2').hide();
  }
  $('.ul-box').html(netul);
  // 点击首页
  $('.head-tabber .nav-box > div')
    .eq(0)
    .click(function() {
      if (clientType) {
        if (from == 1) {
          location.href = './newIndex.html';
        } else {
          location.href = './';
        }
      } else {
        location.href = './mobile.html';
      }
    });
  // 点击关于我们
  $('.head-tabber .nav-box > div')
    .eq(1)
    .click(function() {
      if (from == 1) {
        location.href = './about.html?from=1';
      } else {
        location.href = './about.html';
      }
    });
  // 点击净网行动
  $('.head-tabber .nav-box > div')
    .eq(2)
    .click(function() {
      if (from == 1) {
        location.href = './net24.html?from=1';
      } else {
        location.href = './net24.html';
      }
    });
  // 点击下载
  $('.head-tabber .nav-box > div')
    .eq(3)
    .click(function() {
      console.log($(this).data(), 'aaaa');
      if ($(this).data().type == 1) {
        if (clientType) {
          if (from == 1) {
            location.href = './newIndex.html';
          } else {
            location.href = './';
          }
        } else {
          location.href = './mobile.html';
        }
      } else {
        window.scrollTo(0, document.body.scrollHeight);
      }
    });
  // 点击充值
  $('.head-tabber .nav-box > div')
    .eq(4)
    .click(function() {
      if (from == 1) {
        location.href = './recharge.html?from=1';
      } else {
        location.href = './recharge.html';
      }
    });
  // 点击底部关于我们
  $('.foot span')
    .eq(0)
    .click(function() {
      if (from == 1) {
        location.href = './about.html?from=1';
      } else {
        location.href = './about.html';
      }
    });

  // 点击安卓下载
  $('.head-load span')
    .eq(0)
    .click(function() {
      console.log('安卓下载');
      window.location.href = 'https://qingtian.tt.cn/download/qingtian_zhubo.apk';
      // onTips();
    });
  // 点击ios下载
  $('.head-load span')
    .eq(1)
    .click(function() {
      // onTips();
      // window.open('https://itunes.apple.com/cn/app/id1526813998?mt=8');
      window.open('https://apps.apple.com/cn/app/id1579457180');
    });

  // 底部ios下载
  $('.story-app div')
    .eq(0)
    .click(function() {
      // onTips();
      // window.open('https://itunes.apple.com/cn/app/id1526813998?mt=8');
      window.open('https://apps.apple.com/cn/app/id1579457180');
    });
  // 底部安卓下载
  $('.story-app div')
    .eq(1)
    .click(function() {
      // onTips();
      window.location.href = 'https://qingtian.tt.cn/download/qingtian_zhubo.apk';
    });
});
